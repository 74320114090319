body{
  margin: 0 auto !important;
}

.container{
  max-width: 1366px !important;
}

.table{
  display:block;
  overflow: auto;
}
